
import './App.css';
import Banner from './Banner/Banner';
import Navbar from './Navbar/Navbar';
import How from './How/How';
import Aboutuspage from './Aboutuspage/Aboutuspage';
import FAQs from './FAQs/FAQs';
import Aboutus from './Aboutus/Aboutus';
import React, {useEffect, useState} from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Footer from './Footer/Footer';
import Loading from './Banner/Loading';
import Service from './Service/Service';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  useEffect(() => {
    Aos.init({
      duration:800,
      once: true
    })
  }, [])
  const [isLoading, setIsLoading] = useState(true);
  const [loadedMeetups, setLoadedMeetups] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    fetch(
      'https://react-getting-started-48dec-default-rtdb.firebaseio.com/meetups.json'
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const meetups = [];

        for (const key in data) {
          const meetup = {
            id: key,
            ...data[key]
          };

          meetups.push(meetup);
        }

        setIsLoading(false);
        setLoadedMeetups(meetups);
      });
  }, []);

  if (isLoading) {
    return (
      <section>
        <Loading/>
      </section>
    );
  }
  return (
    <div className="App">
      <Navbar/>
      <Banner/>
      <Aboutus/>
      <How/>
      <Service/>
      <h1 style={{display:'flex', alignItems:'center', justifyContent:'center', padding:'5%'}}>FAQs</h1>
      <FAQs/>
      <Footer/>

    </div>
  );
}

export default App;
