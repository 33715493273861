import React from "react";
import './Banner.css'
const Banner = () => {
  return (
    <div className="Banner">
      <h1 data-aos="fade-left" data-aos-duration="1050">Transforming Your Merchant Outreach <br/>From Data to Deals</h1>
    </div>
  );
};

export default Banner;