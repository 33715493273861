import React from "react";
import How1 from './img/how.jpg'
import './How.scss'
const How = () => {

    return (
        <div className="Aboutus-Container">
            <div className="Left-Side">
            <img data-aos="fade-right" data-aos-duration="1050" className="Aboutus-img" src={How1} alt="Foto"/>
            </div>
            <div  className="Right-Side">
            <h1 data-aos="fade-left" data-aos-duration="1050" >How it works?</h1>
                <p data-aos="fade-left" data-aos-duration="1050">
                Professional Outreach: Our team uses proven methods, including direct communication and personalized engagement, to introduce your business. <br/> <br/>
                <p data-aos="fade-left" data-aos-duration="1050">Qualified Leads & Appointments: We ensure every lead meets your criteria before scheduling appointments with decision-makers.</p>
</p>
             
            </div>
        </div>

    );
};

export default How;