
import '../App.css';
import Navbar from '../Navbar/Navbar';
import Aboutus from '../Aboutus/Aboutus';
import React, {useEffect, useState} from 'react';

import 'aos/dist/aos.css';
import Footer from '../Footer/Footer';


function App() {
  return (
    <div className="App">
      <Navbar/>
      <Aboutus/>
      <Footer/>
    </div>
  );
}

export default App;
