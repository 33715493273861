import React, {useState, useRef, useEffect} from "react";
import "./Navbar.css";
import { Link } from 'react-router-dom';
import Logo from './Logo/Logo.png'
import {GiHamburgerMenu} from 'react-icons/gi';
import SideBar from './Mobile-navbar/SideBar';
import Backdrop from './Mobile-navbar/Backdrop';
import {TweenMax, Power3} from 'gsap';

const Navbar = () => {
    let logoItem = useRef(null)

  useEffect(() =>{
    console.log(logoItem);
    TweenMax.to(
      logoItem,
      2,
      {
        opacity:1,
        x: 20,
        ease: Power3.easeOut
      }
    )
  })
  const [sideDraw, setsideDraw] = useState(false);
  const onButton = () => {
    setsideDraw((prev) => !prev);
  }
  useEffect(() => {
    const handleTouchMove = (event) => {
      if (sideDraw) {
        event.preventDefault();
      }
    };
    if (sideDraw) {
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
    }

    // Remove event listener when the component unmounts or sidebar is closed
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [sideDraw]);
  return (
    <nav className="navbar" data-aos="fade-down">
      <div className="logo-container">
        <img src={Logo} alt="Logo" className="logo" /> 
      </div>
      <ul className="navbar-links">
        <li className="navbar-item"><a href="#aboutus">About Us</a></li>
        <li className="navbar-item"><a href="#pricing">Pricing</a></li>
        <li className="navbar-item"><a href="#faqs">FAQs</a></li>
        
        <GiHamburgerMenu className='Menu-Button' onClick={onButton}/>
        {
                    sideDraw && <SideBar onClose={onButton} />

                }
                {
                    sideDraw && <Backdrop onClose={onButton} />

                }
      </ul>
    </nav>
  );
};

export default Navbar;