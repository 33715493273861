import React from 'react';
import './Servicess.scss';
import ServicesFoto from './Image/Services.jpg'

function Service() {
  return (
    <div className="services-container">
      <div className="services-image">
        <img data-aos="fade-right" data-aos-duration="1050"
          src= {ServicesFoto}
          alt="Services"
        />
      </div>
      <div className="services-text">
        <h2 data-aos="fade-left" data-aos-duration="1050">Services We Offer</h2>
        <div className="service">
          <h3 data-aos="fade-left" data-aos-duration="1050" >1. Tailored Lead Generation</h3>
          <p data-aos="fade-left" data-aos-duration="1050" >
            We identify and connect you with businesses that match your ideal
            customer profile. Our lead generation strategies are customized to
            ensure the highest quality leads, driving meaningful engagements and
            maximizing your sales pipeline.
          </p>
            {/*  <h4 data-aos="fade-left" data-aos-duration="1050">Key Features:</h4> 
          <ul data-aos="fade-left" data-aos-duration="1050">
            <li data-aos="fade-left" data-aos-duration="1050">1. Advanced data mining to target high-potential businesses.</li>
            <li data-aos="fade-left" data-aos-duration="1050">2. Industry-specific lead qualification.</li>
            <li data-aos="fade-left" data-aos-duration="1050">3. Real-time updates on lead progress.</li>
            
          </ul> 
          */}
        </div>
        <div className="service">
          <h3 data-aos="fade-left" data-aos-duration="1050">2. Merchant Outreach</h3>
          <p data-aos="fade-left" data-aos-duration="1050">
            We handle professional communication with businesses on your behalf,
            creating a seamless and professional introduction to your services.
          </p>
          {/*
          <h4 data-aos="fade-left" data-aos-duration="1050">Key Features:</h4>
          <ul data-aos="fade-left" data-aos-duration="1050">
            <li data-aos="fade-left" data-aos-duration="1050">1. Direct outreach through calls, emails, and other proven communication channels.</li>
            <li data-aos="fade-left" data-aos-duration="1050">2. Personalized engagement strategies to build trust with merchants.</li>
            <li data-aos="fade-left" data-aos-duration="1050">3. Expertise in positioning your business as a top choice for merchant services.</li>
          </ul>*/}
        </div>
        <div className="service">
          <h3 data-aos="fade-left" data-aos-duration="1050">3. Appointment Scheduling</h3>
          <p data-aos="fade-left" data-aos-duration="1050">
            Save time and focus on closing deals while we manage the scheduling of
            high-quality appointments with decision-makers who are ready to engage.
          </p>{/*
          <h4 data-aos="fade-left" data-aos-duration="1050">Key Features:</h4>
          <ul>
            <li data-aos="fade-left" data-aos-duration="1050">1. Pre-qualified appointments based on your criteria.</li>
            <li data-aos="fade-left" data-aos-duration="1050">2. Calendar integration to keep your schedule organized.</li>
            <li data-aos="fade-left" data-aos-duration="1050">3. Flexibility to accommodate different time zones and regions.</li>
          </ul>*/}
        </div>
        <div className="service">
          <h3 data-aos="fade-left" data-aos-duration="1050">4. Industry-Specific Consulting</h3>
          <p data-aos="fade-left" data-aos-duration="1050">
            Leverage our years of experience in the merchant services sector to
            refine your outreach strategies and improve your sales process.
          </p>
          {/*
          <h4 data-aos="fade-left" data-aos-duration="1050">Key Features:</h4>
          <ul >
            <li data-aos="fade-left" data-aos-duration="1050">1. Market analysis tailored to your business.</li>
            <li data-aos="fade-left" data-aos-duration="1050">2. Insight into trends within retail, restaurants, and service-based industries.</li>
            <li data-aos="fade-left" data-aos-duration="1050">3. Guidance on optimizing your value proposition.</li>
          </ul>
          */}
        </div>
      </div>
    </div>
  );
}

export default Service;
