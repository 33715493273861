import React from "react";
import Aboutus1 from './Image/aboutus.jpg'
import './Aboutus.scss'
const Aboutus = () => {

    return (
        <div className="Aboutus-Container">
            <div data-aos="fade-left" data-aos-duration="1050" className="Left-Side">
                <img data-aos="fade-right" data-aos-duration="1050" className="Aboutus-img" src={Aboutus1} alt="Foto"/>
            </div>
            <div  className="Right-Side">
                <h1 data-aos="fade-left" data-aos-duration="1050">About us</h1>
                <p data-aos="fade-left" data-aos-duration="1050">At MerchantConnects, we bridge the gap between merchant service providers and high-potential businesses. Our mission is to simplify the sales process by offering tailored lead generation, precise merchant outreach, and reliable appointment scheduling—all designed to maximize your sales efficiency.<br/>
                <br/>
With a deep understanding of the merchant services industry, we specialize in connecting businesses with service providers through a streamlined and professional approach. Our team is passionate about creating value for our clients, helping them expand their customer base and grow their revenue.
</p>
            </div>
        </div>

    );
};

export default Aboutus;