import React, {useState} from "react";
import './Footer.scss';
import { BiPhone, BiLogoLinkedinSquare, BiMailSend } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import Logo from './Image/Logo.png'
function Footer () {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        businessName: '',
        message: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log("Form Submitted:", formData);
        alert("Thank you for contacting us!");
        // Reset form
        setFormData({
          name: '',
          email: '',
          businessName: '',
          message: ''
        });
      };
    const phoneNumber = '+1 908-528-8431';
    const linkedin1 = 'www.linkedin.com/in/adis-pallqa/';
    const emailAddress = 'adis.pallqa@merchantsconnects.com';
    return (
        <div className="Footer-Container">
            <div className="Footer-Logo-Container">
                <img src={Logo}  alt="Logo1" />
                <h3 >Merchant Connects LLC</h3>
            </div>


            <div className="Footer-Contact-form">
                <p >Contact us</p>
                <a className="Phone-container"  href={`tel:${phoneNumber}` }><BiPhone style={{color:'black'}}/>+1 908-528-8431  </a>
                <a className="Email-Containter"  href={`tel:${emailAddress}`} ><BiMailSend style={{color:'black'}} />info@merchantconnects.com</a>
            </div>


      <form onSubmit={handleSubmit} data-aos="fade-left" data-aos-duration="1000" className="contact-form">
        <div className="form-group">
          <label  htmlFor="name">Name:</label>
          <input 
            type="text" 
            id="name" 
            name="name" 
            value={formData.name} 
            onChange={handleChange} 
            placeholder="Enter your name" 
            required 
          />
        </div>

        <div className="form-group">
          <label  htmlFor="email">Email Address:</label>
          <input  
            type="email" 
            id="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            placeholder="Enter your email" 
            required 
          />
        </div>

        <div className="form-group">
          <label  htmlFor="businessName">Business Name:</label>
          <input 
            type="text" 
            id="businessName" 
            name="businessName" 
            value={formData.businessName} 
            onChange={handleChange} 
            placeholder="Enter your business name" 
            required 
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">Message/Inquiry:</label>
          <textarea 
            id="message" 
            name="message" 
            value={formData.message} 
            onChange={handleChange} 
            placeholder="Write your message here" 
            required 
          />
        </div>

        <button type="submit" className="cta-btn">Contact Us Today</button>
      </form>
        </div>
    );
}   
export default Footer;