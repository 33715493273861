import React, { useState } from "react";
import './FAQs.scss'

const FAQs = () => {
  const faqs = [
    {
      question: "How do you find merchant details?",
      answer: "We use advanced data mining techniques and industry-specific tools to identify merchants who fit your ideal customer profile."
    },
    {
      question: "What industries do you specialize in?",
      answer: "We specialize in retail, restaurants, and service-based industries but can adapt to any sector where merchant services are in demand."
    },
    {
      question: "How do you ensure leads are qualified?",
      answer: "Our team verifies merchant interest, checks business viability, and ensures readiness for appointments before scheduling."
    },
    {
        question: "What regions do you cover?",
        answer: "Our services extend to all major regions in the U.S. and internationally, depending on client needs."
    },
    {
        question: " How do I get started?",
        answer: "Simply contact us through our website, and we’ll discuss your goals and requirements to design a customized outreach strategy."
    },
  ];

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <div  className="faq-question" data-aos="fade-left" data-aos-duration="1050" onClick={toggleAnswer}>
        {question}
        <span  className="faq-toggle">{isOpen ? "↑" : "↓"}</span>
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

export default FAQs;
